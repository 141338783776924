<template>
    <div class="filter">
        <form >
            <div class="filterInputs">
                <input class="uk-input" v-model="domainname" @keyup="doFilter" placeholder="Domainname">
                
                <input class="uk-input" v-model="hostingserver" @keyup="doFilter" placeholder="Hosting-Server">
            
                <input class="uk-input" v-model="cms" @keyup="doFilter" placeholder="CMS">
                <input class="uk-input" v-model="emailsystem" @keyup="doFilter" placeholder="Postfach">
            </div>
            <br>
            
        </form>

    </div>

</template>

<script>
import WebsiteListFilter from '@/components/WebsiteListFilter.vue'


export default {
    name: 'WebsiteListFilter',
    components: {
        WebsiteListFilter
    },
    data() {
        return {
            domainname: '',
            hostingserver: '',
            cms: '',
            emailsystem: '',
            
        }
    },
    emits: {
        'filter-list': function(domainname) {}
    },
    props: {
        
        
    },
    methods: {
        doFilter(event) {
            event.preventDefault();

            this.$emit('filter-list', this.domainname, this.hostingserver, this.cms, this.emailsystem);
            
            
        },
        
    }

}
</script>
<style lang="scss" scoped>
/*
    .filterInputs {
        display: flex;
        flex-direction: row;
        input {
           width: 200px; 
        }
        
    }
*/
</style>