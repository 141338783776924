<template>
  <div class="home">
    <h1>Liste unserer Websites</h1>
    <div class="uk-container">
   
    <WebsiteListFilter
      @filter-list="filterList"
    ></WebsiteListFilter>
   <br>
    <WebsiteList
      :domainname-filter="searchFilterDomainname"
      :hostingserver-filter="searchFilterHostingserver"
      :cms-filter="searchFilterCms"
      :emailsystem-filter="searchFilterEmailsystem"
    ></WebsiteList>
   
  </div>


   </div>
</template>

<script>
// @ is an alias to /src
import WebsiteList from '@/components/WebsiteList.vue'
import WebsiteListFilter from '@/components/WebsiteListFilter.vue'

export default {
  name: 'Home',
  components: {
    WebsiteList,
    WebsiteListFilter
  },
  data() {
    return {
      searchFilterDomainname: '',
      searchFilterHostingserver: '',
      searchFilterCms: '',
      searchFilterEmailsystem: '',
    }
  },
  
  
  methods: {

    filterList(domainname, hostingserver, cms, emailsystem) {
      this.searchFilterDomainname = domainname;
      this.searchFilterHostingserver = hostingserver;
      this.searchFilterCms = cms;
      this.searchFilterEmailsystem = emailsystem;
    },
    /*
    fetchData() {
      this.axios.get('https://www.vogel.media/serverlist').then(response => (responseData = response));
    },*/
/*
    loadWebsiteData() {
      fetch('https://www.vogel.media/serverlist').then((response) => {
        if(response.ok) {
          return response.json();
        }
      }).then((data) => {
        
      
        const results = [];
        this.websites= data.data;

        
        
        
      })
    },
*/   

  },
  
}
</script>
