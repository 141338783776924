
<template>
  <div class="website">
    <div class="listitem">
      <div class="listcontent">
          <div class="id" >{{ values.id }}</div>
          <div class="domainname"><a :href="www" target=_blank rel=noopener >{{ values.domainname }}</a></div>
          <div class="hostingserver"><a :href="hostingserverAdress"  target=_blank rel=noopener>{{ values.hostingserver }}</a></div>
          <div class="cms" >{{ values.cms }}</div>
          <div class="cms-tools" v-if="values.cms === 'Contao'">
            <a :href="contaoBackendAddress"  target=_blank rel=noopener>BACK</a>
            <a :href="contaoManagerAddress"  target=_blank rel=noopener>MNGR</a>
            <a :href="contaoInstallAddress"  target=_blank rel=noopener>INST</a>
          </div><div class="cms-tools" v-else>&nbsp;</div>
          <div class="ip" v-clipboard="values.ip"><a>{{ values.ip }}</a></div>
          <div class="ip" >{{ values.emailsystem }}</div>
          <div class="pingstatus " :class="[pingstatus]"><i class="fas fa-circle"></i></div>

      </div>
      <div class="listactions">

        <a class="uk-button " @click="toggleShow()" ><i class="far fa-edit"></i></a>
        <a class="uk-button " @click="toggleDeleteShow()" ><i class="fas fa-trash-alt"></i></a>
      </div>

    </div>
    <div class="edit" :class="{'active':showEditing}"  >
      <div class="night" @click="toggleShow()"></div>

      <WebsiteListEdit
  
      
       :itemid="values.id"
       :domainname="values.domainname" 
      :hostingserver="values.hostingserver"
      :cms="values.cms"
      :ip="values.ip"
      :emailsystem="values.emailsystem"
      :www="values.www"
      :bemerkungen="values.bemerkungen"
      :ping="values.ping"
       @save="saveItem"
       @cancel="toggleShow"

      ></WebsiteListEdit>


    </div>
    <div class="delete" :class="{'active':showDelete}"  >
      <div class="night" @click="toggleDeleteShow()"></div>

      <WebsiteListItemDelete
  
       :itemid="values.id"
       :domainname="values.domainname" 
      

       @delete="deleteItem"
       @cancel="toggleDeleteShow"

      ></WebsiteListItemDelete>


    </div>
  </div>
</template>
 
<script>

import axios from 'axios';
import WebsiteListEdit from '@/components/WebsiteListEdit.vue'
import WebsiteListItemDelete from '@/components/WebsiteListItemDelete.vue'

export default {
  name: 'WebsiteListItem', 
  components: {
    WebsiteListEdit,
    WebsiteListItemDelete
  },
  props: {
    domainname: String,
    hostingserver: String,
    cms: String,
    www: String,
    bemerkungen: String,
    values: Object,
    emailsystem: String,
    ping: Boolean,



    
  },
  emits: ['itemdeleted'],

  data() {
    return {

      showEditing: false,
      showDelete: false,
      tmpvalues: Object,
      //_tmpvals: Object,

      pingstatus: 'grey',
      
    }
  },
  
  methods: {
      toggleEditing: function(event) {
        event.preventDefault();
        this.showEditing = !this.showEditing;
        
        
      },
      toggleShow(){
    	  this.showEditing = !this.showEditing
      },
      toggleDeleteShow(){
    	  this.showDelete = !this.showDelete
      },
      getPingStatus(){
        if(this.values.ping === false) {
          return 'deaktiviert';
        }else{
          if( this.values.lastping > 0){
            if((this.values.lastbadping) && (this.values.lastbadping >=  this.values.lastping)) {
              return 'red';
              
            }else{
              return 'green';
            }

          }else{
            return 'grey';
          }
        }
      },

      saveItem: function(
            tmpitemid, 
            tmpdomainname, 
            tmphostingserver,
            tmpcms,
            tmpip,
            tmpemailsystem,
            tmpwww, 
            tmpbemerkungen, 
            tmpping,

      ) {
        
        const formData = {
          id: tmpitemid,
          app: "websites",
          function: "updateWebsiteEntry",
          domainname: tmpdomainname,
          hostingserver: tmphostingserver,
          cms: tmpcms,
          ip: tmpip,
          emailsystem: tmpemailsystem,
          www: tmpwww,
          bemerkungen: tmpbemerkungen,
          ping: tmpping,
        }
        console.log(formData.ping);
        if(formData.ping === true) {
          formData.ping = "1";
        }else {
          formData.ping = "0";
        }
        
        axios.post('https://www.vogel.media/server.php',JSON.stringify( formData))
        .then(
           res => {
              this.getWebsiteEntry()
           }
          
        
        
        )

       },





      deleteItem: function(
         

      ) {
        
        const formData = {
          id: this.tmpvalues.id,
          app: "websites",
          function: "deleteWebsiteEntry",
          
        }
        //console.log(formData);
        
        axios.post('https://www.vogel.media/server.php',JSON.stringify( formData))
        .then( result => {
            this.$emit('itemdeleted')
         }
        
        )

       },

      getWebsiteEntry: function() {
       
        const formData = {
          id: this.tmpvalues.id,
          app: "websites",
          function: "getWebsiteEntry",
        }
        //console.log(formData);
        
        axios.post('https://www.vogel.media/server.php',JSON.stringify( formData))
        .then(res => {
            const objekt = res;

            this.values.domainname = objekt.data[0]['domainname'] ;
            this.values.hostingserver = objekt.data[0]['hostingserver'] ;
            this.values.cms = objekt.data[0]['cms'] ;
            this.values.ip = objekt.data[0]['ip'] ;
            this.values.emailsystem = objekt.data[0]['emailsystem'] ;
            this.values.www = objekt.data[0]['www'] ;
            this.values.bemerkungen = objekt.data[0]['bemerkungen'] ;
            this.values.ping = this.stringToBoolean( objekt.data[0]['ping']) ;
            console.log(this.values.ping );
            this.pingstatus = this.getPingStatus();

             if(this.showEditing === true) {
              this.toggleShow();
            }
        
        })
       

       },
       stringToBoolean: function(string) {
         switch(string) {
           case "true": case "yes": case "1": return true;
           case "false": case "no": case "0": return false;
         }
       },
/* 
      saveWebsiteEntry: function() {
        
        const formData = {
          id: this.tmpvalues.id,
          app: "websites",
          function: "updateWebsiteEntry",
          domainname: this.tmpvalues.domainname,
          hostingserver: this.tmpvalues.hostingserver,
          cms: this.tmpvalues.cms,
          ip: this.tmpvalues.ip,
          emailsystem: this.tmpvalues.emailsystem,
          www: this.tmpvalues.www,
        }
        //console.log(formData);
        
        axios.post('https://www.vogel.media/server.php',JSON.stringify( formData))
        .then( result => {
            this.getWebsiteEntry();
        }
        
        )

       },

 */       
  },
  computed: {
      hostingserverAdress: function() {
        return `https://${this.hostingserver}.han-solo.net/easytecc`;
      },
      contaoBackendAddress: function() {
            return `${this.www}/contao`;
      },
      contaoManagerAddress: function() {
            return `${this.www}/contao-manager.phar.php`;
      },
      contaoInstallAddress: function() {
            return `${this.www}/contao/install`;
      },

        
      
  },
  mounted() {
    
      this.tmpvalues = JSON.parse(JSON.stringify(this.values));;
      this.pingstatus = this.getPingStatus();
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

