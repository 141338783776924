<template>
    
      <div class="editForm">
          <div class="content">
            <h2>Websiteeintrag bearbeiten</h2>
            <div class="frame">
                <div class="hidden">
                    <label for="id">id:</label>
                    <input readonly name="id" class="uk-input" v-model="tmpitemid" placeholder="id"> <br />    
                </div>
                <label for="domainname">Name:</label>
                <input name="domainname" class="uk-input" v-model="tmpdomainname" placeholder="Domainname"> <br />    

                <label for="hostingserver">Server:</label>
                <input name="hostingserver" class="uk-input" v-model="tmphostingserver"  placeholder="Hosting-Server">   <br />           

                <label for="www">Website:</label>
                <input name="www" class="uk-input" v-model="tmpwww"  placeholder="Website"> <br /> 

                <label for="cms">CMS:</label>
                <input name="cms" class="uk-input" v-model="tmpcms"  placeholder="CMS"> <br /> 

                <label for="ip">IP:</label>
                <input name="ip" class="uk-input" v-model="tmpip"  placeholder="IP"> <br /> 

                <label for="postfach">Postfach:</label>
                <input name="postfach" class="uk-input" v-model="tmpemailsystem"  placeholder="Postfach"> <br /> 

                <label for="bemerkungen">Bemerkungen:</label>
                <textarea name="bemerkungen" class="uk-input" v-model="tmpbemerkungen"  placeholder="Bemerkungen"> <br /> </textarea>



            </div>
          </div>
          <div class="frame left">
                <Switch v-model:checked="tmpping" label="Monitoring über Ping aktivieren" />

          </div>
          <div class="frame">
                <button class="uk-button uk-button-default ok-btn" @click="doSave">Speichern</button>
                <button class="uk-button uk-button-default" @click="doCancel">Schließen</button>
          </div>
     
      </div>

</template>

<script>
import WebsiteListEdit from '@/components/WebsiteListEdit.vue'
import Switch from '@/components/Switch.vue'

export default {

    name: 'WebsiteListEdit', 
    components: {
        WebsiteListEdit,
        Switch
    },


  props: {
    isnew: Boolean,

    itemid: String,
    domainname: String,
    hostingserver: String,
    cms: String,
    ip: String,
    emailsystem: String,
    www: String,
    ping: Boolean,
     bemerkungen: String,
    
    //initialvalue: Object,
  },


  
  data() {
    return {
        tmpisnew: true,

        tmpitemid: '',
        tmpdomainname: '',
        tmphostingserver: '',
        tmpcms: '',
        tmpip: '',
        tmpemailsystem: '',
        tmpwww: '', 
        tmpbemerkungen: '', 
        tmpping: false,
       // valueemit: Object.assign({}, this.initialvalue)
    }

    
  },

    methods: {
        doSave(event) {
            event.preventDefault();

            this.$emit('save', 
            this.tmpitemid, 
            this.tmpdomainname, 
            this.tmphostingserver,
            this.tmpcms,
            this.tmpip,
            this.tmpemailsystem,
            this.tmpwww,
            this.tmpbemerkungen,
            this.tmpping
            );
            
        },
        doCancel(event) {
            event.preventDefault();
            if (this.tmpisnew === true) {
 
            this.tmpitemid = '';
            this.tmpdomainname = '';
            this.tmphostingserver = '';
            this.tmpcms = '';
            this.tmpip = '';
            this.tmpemailsystem = '';
            this.tmpwww = '';
            this.tmpbemerkungen = '';
            this.tmpping = false;
             
            }
            this.$emit('cancel');
            
        },
        
    },
    mounted() {
        if(!this.isnew) {
        this.tmpitemid = this.itemid;
        this.tmpdomainname = this.domainname;
        this.tmphostingserver = this.hostingserver;
        this.tmpcms = this.cms;
        this.tmpip = this.ip;
        this.tmpemailsystem = this.emailsystem;
        this.tmpwww = this.www; 
        this.tmpbemerkungen = this.bemerkungen; 
        this.tmpping = this.ping; 
        this.tmpisnew = this.isnew; 
        }
        

    }


}

</script>