<template>

  <div id="nav">
    <div  class="uk-container">
    <div class="logo">
      <img id="vm-logo" width=200 src="logo-vm.png">
<!--
      <svg id="vm-logo" class="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 237.732 50.092">
      <g id="Bildmaterial_4" data-name="Bildmaterial 4" transform="translate(118.866 25.046)">
      <g id="Gruppe_93" data-name="Gruppe 93" transform="translate(-118.866 -25.046)">
      <g id="Gruppe_91" data-name="Gruppe 91" transform="translate(58.092 19.996)">
      <path id="Pfad_702" data-name="Pfad 702" d="M158.9,56.4h1.572l3.692,11.077c.256.731.548,1.9.768,2.778.219-.877.512-2.047.768-2.778L169.392,56.4h1.572l-5.118,15.317h-1.864Z" transform="translate(-158.9 -55.779)"></path>
      <path id="Pfad_703" data-name="Pfad 703" d="M194,71.7c0-3.034,1.718-4.9,4.57-4.9s4.57,1.864,4.57,4.9v2.011c0,3.034-1.718,4.9-4.57,4.9S194,76.743,194,73.673Zm1.5,1.828c0,2.266.841,3.765,3.071,3.765s3.034-1.462,3.034-3.765V71.845c0-2.23-.841-3.692-3.034-3.692s-3.071,1.462-3.071,3.692Z" transform="translate(-181.169 -62.377)"></path>
      <path id="Pfad_704" data-name="Pfad 704" d="M228.684,81.423h3.144a2,2,0,0,0,2.12-2.193V77.072a4.979,4.979,0,0,1-3.254,1.243c-2.522,0-4.094-1.682-4.094-4.46v-2.6c0-2.815,1.535-4.46,4.094-4.46a5.4,5.4,0,0,1,3.4,1.462l.146-1.17h1.17v12.1a3.243,3.243,0,0,1-3.583,3.546H228.94Zm-.548-7.933c0,2.34.841,3.473,2.742,3.473a5.271,5.271,0,0,0,3.071-1.279v-6.1a5.316,5.316,0,0,0-3.071-1.426c-1.9,0-2.742,1.133-2.742,3.473Z" transform="translate(-201.851 -62.377)"></path>
      <path id="Pfad_705" data-name="Pfad 705" d="M259.8,71.3a4.288,4.288,0,0,1,4.387-4.5,4.349,4.349,0,0,1,4.387,4.5v1.243a.439.439,0,0,1-.4.439H261.3v1.17a2.9,2.9,0,0,0,3.034,3.144,12.32,12.32,0,0,0,3.436-.585l.329,1.279a12.393,12.393,0,0,1-3.765.658,4.314,4.314,0,0,1-4.533-4.46Zm7.275.475v-.548a2.9,2.9,0,1,0-5.776,0v.548Z" transform="translate(-222.915 -62.377)"></path>
      <path id="Pfad_706" data-name="Pfad 706" d="M292.5,54.7H294V68.665c0,.695.256.841.658.95l-.292,1.279c-.95-.073-1.828-.4-1.828-2.047Z" transform="translate(-243.661 -54.7)"></path>
      <path id="Pfad_707" data-name="Pfad 707" d="M322.42,56.4h1.864l3.29,11.588c.256.914.475,1.243.95,1.243s.695-.329.95-1.243l3.29-11.588h1.864l2.12,15.317h-1.5l-1.535-10.711c-.073-.695-.183-1.718-.256-2.522-.183.841-.439,1.828-.621,2.559l-1.974,7.2c-.4,1.462-1.024,2.376-2.376,2.376s-1.974-.914-2.376-2.376l-1.974-7.238c-.183-.695-.475-1.755-.621-2.559-.073.877-.146,2.011-.256,2.705L321.8,71.717h-1.5Z" transform="translate(-261.298 -55.779)"></path>
      <path id="Pfad_708" data-name="Pfad 708" d="M372.4,71.3a4.288,4.288,0,0,1,4.387-4.5,4.349,4.349,0,0,1,4.387,4.5v1.243a.439.439,0,0,1-.4.439H373.9v1.17a2.9,2.9,0,0,0,3.034,3.144,12.321,12.321,0,0,0,3.436-.585l.329,1.279a12.393,12.393,0,0,1-3.765.658,4.314,4.314,0,0,1-4.533-4.46Zm7.275.475v-.548a2.9,2.9,0,1,0-5.776,0v.548Z" transform="translate(-294.352 -62.377)"></path>
      <path id="Pfad_709" data-name="Pfad 709" d="M403.8,63.583c0-2.815,1.535-4.46,4.094-4.46a5.281,5.281,0,0,1,3.254,1.353V54.7h1.5V70.639h-1.17l-.146-1.17a5.4,5.4,0,0,1-3.4,1.462c-2.559,0-4.094-1.682-4.094-4.46Zm1.5,2.486c0,2.34.841,3.473,2.742,3.473a5.266,5.266,0,0,0,3.071-1.389V61.9a5.175,5.175,0,0,0-3.071-1.426c-1.9,0-2.742,1.133-2.742,3.473Z" transform="translate(-314.274 -54.7)"></path>
      <path id="Pfad_710" data-name="Pfad 710" d="M437.6,57.246a.914.914,0,0,1,.95-.95.95.95,0,0,1,1.024.95,1.024,1.024,0,0,1-1.024,1.024.987.987,0,0,1-.95-1.024Zm.219,3.144h1.5V71.21a.4.4,0,0,1-.439.439h-1.06Z" transform="translate(-335.716 -55.71)"></path>
      <path id="Pfad_711" data-name="Pfad 711" d="M457.519,77.119a5.154,5.154,0,0,1-3.436,1.426c-2.23,0-3.583-1.353-3.583-3.254s1.938-3.254,4.789-3.254h2.047v-1.1c0-1.9-.695-2.888-2.705-2.888a9.8,9.8,0,0,0-3.034.512l-.329-1.279a11.149,11.149,0,0,1,3.363-.585c2.851,0,4.2,1.608,4.2,3.912v7.64h-1.17Zm-.146-3.875H455.4c-2.011,0-3.363.475-3.363,2.011s.695,1.937,2.193,1.937a4.679,4.679,0,0,0,3.144-1.353Z" transform="translate(-343.902 -62.313)"></path>
      <path id="Pfad_712" data-name="Pfad 712" d="M497.4,61.3c0-3.656,1.937-5.7,5.447-5.7a14.878,14.878,0,0,1,4.24.658l-.292,1.316a13.928,13.928,0,0,0-3.875-.621c-3.107,0-3.948,2.047-3.948,4.57v4.021c0,2.522.841,4.533,3.948,4.533a14.806,14.806,0,0,0,3.546-.548v-5.3l1.462-.183v6.58a18.059,18.059,0,0,1-5.081.877c-3.473,0-5.447-2.011-5.447-5.666Z" transform="translate(-373.657 -55.271)"></path>
      <path id="Pfad_713" data-name="Pfad 713" d="M536.237,67.092h1.17l.183,1.17a5.141,5.141,0,0,1,3.217-1.462,3.819,3.819,0,0,1,3.217,1.645,6.01,6.01,0,0,1,3.582-1.645c2.486,0,3.692,1.5,3.692,3.765v7.311a.4.4,0,0,1-.439.439H549.8V70.931c0-1.791-.585-2.815-2.413-2.815a4.9,4.9,0,0,0-3,1.426,5.124,5.124,0,0,1,.11,1.024v7.75H543V70.931c0-1.791-.585-2.815-2.413-2.815a4.737,4.737,0,0,0-2.888,1.426v8.774h-1.5Z" transform="translate(-398.273 -62.377)"></path>
      <path id="Pfad_714" data-name="Pfad 714" d="M587.6,54.7h1.5v5.7a5.252,5.252,0,0,1,3.253-1.279c2.559,0,4.094,1.682,4.094,4.46v2.851c0,2.778-1.535,4.46-4.094,4.46a5.416,5.416,0,0,1-3.4-1.389l-.146,1.133H587.6Zm1.5,13.526a5.317,5.317,0,0,0,3.071,1.353c1.938,0,2.778-1.133,2.778-3.473V63.949c0-2.34-.8-3.473-2.778-3.473a5.386,5.386,0,0,0-3.071,1.353Z" transform="translate(-430.883 -54.7)"></path>
      <path id="Pfad_715" data-name="Pfad 715" d="M621.5,56.4H623v6.8h7.494V56.4h1.535V71.278a.4.4,0,0,1-.439.439h-1.1V64.552H623v7.165h-1.5Z" transform="translate(-452.39 -55.779)"></path>
      </g>
      <g id="Gruppe_92" data-name="Gruppe 92">
      <path id="Pfad_716" data-name="Pfad 716" d="M34.1,19.119,2.406,24.712A2.925,2.925,0,0,0,.029,28.076L2.04,39.335,15.858,56.772l19.7-37.543A2.925,2.925,0,0,0,34.1,19.119Z" transform="translate(0.011 -12.101)" fill="#ff8c00"></path>
      <path id="Pfad_717" data-name="Pfad 717" d="M69.065,23.987A2.924,2.924,0,0,0,68.7,23L49.8,62.993l22.519-3.985a2.925,2.925,0,0,0,2.376-3.4Z" transform="translate(-31.591 -14.592)" fill="#ff8c00"></path>
      <path id="Pfad_718" data-name="Pfad 718" d="M12.355,112.953a2.925,2.925,0,0,0,3.4,2.376l5.374-.95L10.6,102.9Z" transform="translate(-6.721 -65.284)" fill="#ff8c00"></path>
      <path id="Pfad_719" data-name="Pfad 719" d="M82.088,15.975,80,4.2A5.045,5.045,0,0,0,75.033,0l-.877.073L62.348,2.157a5.045,5.045,0,0,0-3.583,2.632l-.366.658L74.594,2.6h.439a2.486,2.486,0,0,1,2.449,2.084l2.851,16.194.512-.548a5.045,5.045,0,0,0,1.243-4.35Z" transform="translate(-37.047)" fill="#ff8c00"></path>
      </g>
      </g>
      </g>
      </svg>
-->
      </div>

    <div class="navigation">
    <router-link to="/">Websites</router-link> |
    <router-link to="/server">Server</router-link>
    </div>
    </div>
  </div>
  <router-view/>
</template>

<style lang="scss">
#app {
  font-family: alwyn-new-web, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;

  padding-bottom: 3em;
}

h1, h2, h3 {
  font-family: alwyn-new-web, sans-serif !important;
  font-weight: bold;
  color: #ff8c00;
}

#nav {

    padding: 10px;
    background-color: #f0f0f0;
    margin-bottom: 30px;




  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #ff8c00;
    }
  }

}

.ok-btn {
  background-color: #ff8c00;
  color: white;
  &:hover {
    background-color: #ffad49;
    color: white;
  }
}
.logo {
  svg {
    width: 200px;
    padding: 1em;
  }
}
</style>
