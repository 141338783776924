<template>
    
      <div class="editForm">
          <div class="content">
            <h2>Websiteeintrag löschen</h2>
            <div class="frame">
                <div class="hidden">
                    <label for="id">id:</label>
                    <input readonly name="id" class="uk-input" v-model="tmpitemid" placeholder="id"> <br />    
                </div>
                <div>
                    <p>Eintrag für Website "{{ tmpdomainname }}" wirklich löschen?</p>
                </div>  
                
            </div>
          </div>

          <div class="frame">
                <button class="uk-button uk-button-default ok-btn" @click="doDelete">Löschen</button>
                <button class="uk-button uk-button-default" @click="doCancelDelete">Abbrechen</button>
          </div>
     
      </div>

</template>

<script>
import WebsiteListItemDelete from '@/components/WebsiteListItemDelete.vue'

export default {

    name: 'WebsiteListItemDelete', 
    components: {
        WebsiteListItemDelete
    },


  props: {


    itemid: String,
    domainname: String,
    
     
    //initialvalue: Object,
  },


  
  data() {
    return {
        tmpitemid: '',
        tmpdomainname: '',
        
    
       // valueemit: Object.assign({}, this.initialvalue)
    }

    
  },

    methods: {
        doDelete(event) {
            event.preventDefault();
            this.$emit('delete');
            
        },
        doCancelDelete(event) {
            event.preventDefault();
            this.$emit('cancel');
            
        },
        
    },
    mounted() {

        this.tmpitemid = this.itemid;
        this.tmpdomainname = this.domainname;

    }


}

</script>