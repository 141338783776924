<template>

  <div class="websiteList">

    <div class="newentry">
        <div class="websiteCount">{{ getFilteredListCount }} Einträge gefunden</div>
        <button class="uk-button uk-button-default ok-btn" @click="toggleShow()">Neuen Eintrag hinzufügen</button>
   </div>
    <div class="edit" :class="{'active':showEditing}"  >
      <div class="night" @click="toggleShow()"></div>


      <WebsiteListEdit

      isnew
      :itemid="values.itemid" 
      :domainname="values.domainname" 
      :hostingserver="values.hostingserver"
      :cms="values.cms"
      :ip="values.ip"
      :emailsystem="values.emailsystem"
      :www="values.www"
        :ping="values.ping"
       @save="saveItem"
       @cancel="toggleShow"

      ></WebsiteListEdit>

   </div>
    <div v-if="isLoading">
        Lädt...
    </div>
    <div v-else-if="!isLoading && (!websites || websites.length === 0)">
        Keine Website-Daten gefunden.
    </div>
    <div v-else-if="!isLoading && websites && websites.length > 0">

  <div class="website header">
    <div class="listitem">
      <div class="listcontent">
          <div class="id" >ID</div>
          <div class="domainname">Name</div>
          <div class="hostingserver">Server</div>
          <div class="cms" >CMS</div>
          <div class="cms-tools" >&nbsp;</div>
          <div class="ip" >IP</div>
          <div class="ip" >Postfach</div>
          <div class="pingstatus " >Ping</div>

      </div>
      <div class="listactions">
        &nbsp;
      </div>

    </div>
   </div>





        <WebsiteListItem 
            v-for="website in filteredList"
            :msg="website.domainname"
            :key="website.id"
            :domainname="website.domainname"
            :hostingserver="website.hostingserver"
            :cms="website.cms"
            :emailsystem="website.emailsystem"
            :www="website.www"
            :bemerkungen="website.bemerkungen"
            :values="website"
            :tmpvalues="website"
            :ping="website.ping"
            @itemdeleted="loadWebsiteData"
        />
    </div>


   </div>
</template>

<script>

import axios from 'axios';

import WebsiteListItem from '@/components/WebsiteListItem.vue'
import WebsiteListEdit from '@/components/WebsiteListEdit.vue'

export default {
    name: 'Home',
    components: {
        WebsiteListItem,
        WebsiteListEdit
    },
    props: {
        domainnameFilter: String,
        hostingserverFilter: String,
        cmsFilter: String,
        emailsystemFilter: String,
        
    },
    data() {
        return {
        websites: [],
        isLoading: false,
        showEditing: false,

        values: {
            itemid: '',
            domainname: '',
            hostingserver: '',
            cms: '',
            ip: '',
            emailsystem: '',
            www: '',
            bemerkungen: '',
            ping: false,
        },

        searchDomainname: '',
        searchHostingserver: '',
        searchCms: '',
        searchEmailsystem: '',

        }
    },
  
    methods: {
        /*
        fetchData() {
        this.axios.get('https://www.vogel.media/serverlist').then(response => (responseData = response));
        },*/


        toggleShow(){
            this.showEditing = !this.showEditing
        },

        loadWebsiteData() {

            const formData = {
                
                app: "websites",
                function: "getWebsiteList",
            }

            this.isLoading = true;

/*             
            fetch('https://www.vogel.media/server.php').then((response) => {
                if(response.ok) {
                return response.json();
            }
            }).then((data) => {
                const results = [];
                console.log(data);
                this.websites= data;
                this.isLoading = false;  
                                    
            })
 */

            axios.post('https://server.vogel.media/server.php',JSON.stringify( formData))
            .then(res => {
                const objekt = res;
                
                

/*                 for(row in objekt) {
                    row.ping = this.stringToBoolean(row.ping);
                }
 */                
                return objekt;
            }).then((data) => {

                this.websites= data.data;
                for (var i = 0; i < this.websites.length; i++) {
                    this.websites[i].ping = this.stringToBoolean(this.websites[i].ping);
                }
                console.log(this.websites);
                this.isLoading = false;  

            })

        




        },
    

        saveItem: function(
                tmpitemid,
                tmpdomainname, 
                tmphostingserver,
                tmpcms,
                tmpip,
                tmpemailsystem,
                tmpwww,
                tmpbemerkungen,
                tmpping

        ) {
            
            const formData = {
            app: "websites",
            function: "newWebsiteEntry",
            itemid: tmpitemid,
            domainname: tmpdomainname,
            hostingserver: tmphostingserver,
            cms: tmpcms,
            ip: tmpip,
            emailsystem: tmpemailsystem,
            www: tmpwww,
            bemerkungen: tmpbemerkungen,
            ping: tmpping,
            }
            //console.log(formData);
            
            if(formData.ping === true) {
            formData.ping = "1";
            }else {
            formData.ping = "0";
            }
            axios.post('https://server.vogel.media/server.php',JSON.stringify( formData))
            .then(res => {
                this.toggleShow();
                this.loadWebsiteData();
            })
            
            
            
            
            
            

        },


       stringToBoolean: function(string) {
         switch(string) {
           case "true": case "yes": case "1": return true;
           case "false": case "no": case "0": return false;
         }
       },
      



    },
    computed: {
        filteredList() {
            
            this.searchDomainname = this.domainnameFilter;
            this.searchHostingserver = this.hostingserverFilter;
            this.searchCms = this.cmsFilter;
            this.searchEmailsystem = this.emailsystemFilter;
            return this.websites.filter(website => {
                return website.domainname.toLowerCase().includes(this.searchDomainname.toLowerCase()) 
                && website.hostingserver.toLowerCase().includes(this.searchHostingserver.toLowerCase()) 
                && website.cms.toLowerCase().includes(this.searchCms.toLowerCase())
                && website.emailsystem.toLowerCase().includes(this.searchEmailsystem.toLowerCase())
            })



        },
        getFilteredListCount() {
            return this.filteredList.length;
        }

    },
    mounted() {
        this.loadWebsiteData();
    }
}
</script>

